window.addEventListener('load', () => {
  const swiperBanner = new Swiper('.swiper-banner', {
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    freeMode: true,
    slidesPerView: 1,
    spaceBetween: 16,
    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    }
  });
});
